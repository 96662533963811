<template>
  <div>
    <ConfirmationDialog
      v-model="deleteModal"
      @cancelled="deleteModal = false"
      @confirmed="DeleteItem()"
      :loader="isDeleting"
    />
    <div class="d-flex justify-space-between align-end mb-8">
      <div class="d-flex flex-column">
        <h4 class="mb-2">Search</h4>
        <v-text-field
          v-model="filter"
          solo
          style="max-width: 400px"
          hide-details
          placeholder="Type to search..."
          clearable
          @click:clear="filter = ''"
          :disabled="!this.CompanyList.length > 0"
        ></v-text-field>
      </div>

      <AddCompanyForm v-if="this.conditional_add_company()" />
    </div>

    <v-data-table
      :headers="headers"
      :items="filtered_contact_list"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      calculate-widths
      hide-default-footer
      :loading="!this.CompanyList.length > 0"
      loading-text="Loading... Please wait"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="VisitItem(item)"> mdi-eye </v-icon>
        <v-icon
          small
          @click="
            deleteModal = true;
            deleteItem = item;
          "
          v-if="conditional_delete_company()"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import AddCompanyForm from "./AddCompanyForm.vue";
import ConfirmationDialog from "../../global/ConfirmationDialog.vue";
export default {
  name: "CompaniesTable",
  components: {
    AddCompanyForm,
    ConfirmationDialog,
  },
  data() {
    return {
      deleteModal: false,
      deleteItem: "",
      isDeleting: false,
      page: 1,
      pageCount: 0,
      filter: "",
      itemsPerPage: 20,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: 75,
        },
        { text: "Name", value: "name", width: 200 },
        { text: "Phone", value: "phone", width: 150 },
        { text: "Email", value: "email", width: 325 },
        // { text: "CC Quotation", value: "include_cc_quotation", width: 125 },
        // { text: "CC Delivery", value: "include_cc_delivery", width: 125 },
        { text: "Actions", value: "actions", sortable: false, width: 100 },
        { text: "Notes", value: "notes", width: 200 },
      ],
    };
  },
  methods: {
    VisitItem(company) {
      console.log(company);
      this.$store.commit("SetCurrentSingleCompany", company);
      this.$router.push("/management/" + this.companyType + "/" + company.id);
    },
    DeleteItem() {
      var company = this.deleteItem;
      if (!company) return;
      console.log(company);
      this.isDeleting = true;
      this.$store
        .dispatch("DeleteSingleCompany", company.id)
        .then(() => {
          this.$store.commit("RemoveCompanyFromList", company.id);
        })
        .finally(() => {
          this.isDeleting = false;
          this.deleteModal = false;
          this.deleteItem = false;
        });
    },
    conditional_add_company() {
      const routeName = this.$route.name;
      if (routeName == "Customers")
        return this.checkPermission("can_add_customers");
      else if (routeName == "Suppliers")
        return this.checkPermission("can_add_suppliers");

      return false;
    },

    conditional_delete_company() {
      const routeName = this.$route.name;
      if (routeName == "Customers")
        return this.checkPermission("can_delete_customers");
      else if (routeName == "Suppliers")
        return this.checkPermission("can_delete_suppliers");

      return false;
    },
  },
  mounted() {
    // this.$store.dispatch("fetchFullCompanyList");
    // if (!this.TermList.length > 0) this.$store.dispatch("fetchTerms");
  },
  computed: {
    CompanyList() {
      return this.$store.getters.getCompanyList;
    },
    filtered_contact_list() {
      var filter = "";
      if (this.filter) filter = this.filter.trim();

      if (!filter.length > 0) return this.CompanyList;
      return this.CompanyList.filter((company) => {
        return (
          (company["id"] && company["id"].toString().includes(filter)) ||
          (company["name"] &&
            company["name"]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase())) ||
          (company["email"] &&
            company["email"]
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase()))
        );
        // str.includes("world");
      });
    },
    companyType() {
      if (window.location.href.indexOf("suppliers") > -1) return "suppliers";
      else if (window.location.href.indexOf("customers") > -1)
        return "customers";
      else return "companies";
    },
    // TermList() {
    //   return this.$store.getters.GetTerms;
    // },
  },
  watch: {
    deleteModal(newValue, oldValue) {
      if (newValue == false && newValue != oldValue) this.deleteItem = "";
    },
  },
};
</script>

<style>
</style>