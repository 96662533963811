<template>
  <v-row class="ma-0 pa-4">
    <v-col cols="12" sm="5" md="3">
      <div class="dates-container">
        <!-- <v-btn
          to="/reports/jobs_quoted_by_date/"
          exact
          outlined
          class="mb-3"
          style="grid-column: span 2"
          >View All</v-btn
        > -->
        <div class="d-flex flex-column" style="grid-column: span 2">
          <v-btn
            @click="show_datepicker('start_date')"
            :color="start_date ? 'primary' : ''"
            outlined
            class="mb-3"
          >
            Start Date:
            {{ start_date | YMD_to_DMY }}
          </v-btn>
          <v-btn
            @click="show_datepicker('end_date')"
            :color="end_date ? 'primary' : ''"
            outlined
            class="mb-3"
          >
            End Date:
            {{ end_date | YMD_to_DMY }}
          </v-btn>

          <v-btn @click="reset_dates" color="warning" class="mb-3">
            Reset
          </v-btn>
        </div>

        <v-dialog
          ref="datepicker_dialog"
          v-model="datepicker_modal"
          persistent
          :return-value.sync="temp_date"
          width="290px"
        >
          <v-date-picker
            v-model="temp_date"
            scrollable
            :max="this.datepicker_active_field == 'start_date' ? this.end_date : ''"
            :min="this.datepicker_active_field == 'end_date' ? this.start_date : ''"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hide_datepicker()">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="save_datepicker()">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <!-- <v-btn
          v-for="date_obj in datesNav"
          :key="date_obj.date"
          :to="'/reports/booked_in_by_month/' + date_obj.date"
          outlined
          class="mb-3"
          >{{ date_obj.label }}</v-btn
        > -->
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="9">
      <div class="container">
        <h2 class="page-header mb-9">Jobs Quoted By Date</h2>

        <div
          class="
            d-flex
            justify-space-between
            mb-9
            flex-column flex-sm-row
            align-center
          "
        >
          <v-data-table
            :headers="headers"
            :items="jobs"
            :items-per-page="-1"
            class="elevation-1"
            style="width: 100%"
            group-by="sales_rep_name"
            :loading="is_loading"
            mobile-breakpoint="0"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
            </template>

            <template v-slot:group="{ items }">
              <tr colspan="7">
                <td colspan="7" class="grey lighten-2" style="font-weight: 500">
                  {{
                    items[0].company && items[0].company.sales_rep_id && items[0].sales_rep_name
                      ? items[0].sales_rep_name
                      : 'House Accounts'
                  }}
                </td>
              </tr>
              <tr
                v-for="item in items"
                :key="item.id"
                @click="gotoJob(item.id)"
                style="cursor: pointer"
              >
                <td v-for="header in headers" :key="item.id + ' - ' + header.value">
                  <template
                    v-if="
                      header.value != 'salQuote' &&
                        header.value != 'priority_id' &&
                        header.value != 'status_id' &&
                        header.value != 'salDate'
                    "
                  >
                    {{
                      header.value == 'company'
                        ? item['company']
                          ? item[header.value]['name']
                          : ''
                        : item[header.value]
                    }}
                  </template>

                  <template v-if="header.value == 'salQuote'">
                    {{ item[header.value] | toCurrency }}
                  </template>

                  <template v-if="header.value == 'priority_id'">
                    {{ priorities[item[header.value]] }}
                  </template>

                  <template v-if="header.value == 'salDate'">
                    {{ item['salDate'] | YMD_to_DMY }}
                  </template>
                  <template v-if="header.value == 'status_id'">
                    {{ item['status']['name'] }}
                  </template>
                </td>
              </tr>
              <tr v-if="items && items.length > 0" class="">
                <td></td>
                <td colspan="2">
                  <p class="ma-0">
                    Total Jobs: <strong>{{ items.length }}</strong>
                  </p>
                </td>
                <td colspan="2">
                  <strong>{{ CalculateJobsAssignedTotal(items) | toCurrency }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
            </template>

            <template slot="body.append">
              <tr class="table-footer">
                <td><strong>Total Sales Value</strong></td>
                <td>
                  <strong>{{ CalculateJobsAssignedTotal(jobs) | toCurrency }}</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>Total Jobs</strong></td>
                <td>
                  <strong>{{ jobs.length }}</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
          <div></div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'jobs_quoted_by_date',
  data() {
    return {
      temp_date: new Date().toISOString().substr(0, 10),
      datepicker_modal: false,
      start_date: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1))
        .toISOString()
        .slice(0, 10),
      end_date: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 0))
        .toISOString()
        .slice(0, 10),
      jobs: [],
      is_loading: false,
      dateClusterLoop: [
        { property_name: 'start_date', label: 'Start Date' },
        { property_name: 'end_date', label: 'End Date' },
      ],
      priorities: {
        2: 'Normal',
        3: 'High',
        4: 'Emergency',
      },
      // MonthMap: {
      //   1: 'Jan',
      //   2: 'Feb',
      //   3: 'March',
      //   4: 'April',
      //   5: 'May',
      //   6: 'June',
      //   7: 'July',
      //   8: 'Aug',
      //   9: 'Sep',
      //   10: 'Oct',
      //   11: 'Nov',
      //   12: 'Dec',
      // },
      headers: [
        {
          text: 'Job',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Customer', value: 'company', class: 'autofit-column-heading' },
        {
          text: 'Description',
          value: 'description',
          class: 'autofit-column-heading',
        },
        {
          text: 'Sales Quote',
          value: 'salQuote',
          class: 'autofit-column-heading',
        },
        {
          text: 'Date Quoted',
          value: 'salDate',
          class: 'autofit-column-heading',
        },
        // {
        //   text: 'Priority',
        //   value: 'priority_id',
        //   class: 'autofit-column-heading',
        // },
        {
          text: 'Status',
          value: 'status_id',
          class: 'autofit-column-heading',
        },
      ],
    };
  },
  methods: {
    gotoJob(id) {
      if (id) this.$router.push('/job/' + id);
    },

    CalculateJobsAssignedTotal(jobs) {
      var total = 0;
      var i = 0;
      for (i == 0; i < jobs.length; i++) {
        total += jobs[i].salQuote || 0;
      }
      return total;
    },

    toYYYYmmDD(date) {
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },

    show_datepicker(var_name) {
      this.datepicker_modal = true;
      this.datepicker_active_field = var_name;
      this.temp_date = '';
    },
    hide_datepicker() {
      this.datepicker_modal = false;
      this.datepicker_active_field = '';
      this.temp_date = '';
    },
    save_datepicker() {
      if (this.temp_date && this.datepicker_active_field) {
        this[this.datepicker_active_field] = this.temp_date;
      }
      this.hide_datepicker();
    },
    reset_dates() {
      this.start_date = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1))
        .toISOString()
        .slice(0, 10);
      this.end_date = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 0))
        .toISOString()
        .slice(0, 10);
    },

    FetchData() {
      this.is_loading = true;
      this.jobs = [];
      this.$store
        .dispatch(
          'get_report_data_for_tables',
          'jobs_quoted_by_date?start_date=' + this.start_date + '&end_date=' + this.end_date
        )
        .then((res) => {
          this.jobs = res;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
  computed: {
    // datesNav() {
    //   var today = new Date();
    //   // today = new Date(today.getFullYear(), today.getMonth(), 1);
    //   var dates = [];
    //   // dates.push(this.toYYYYmmDD(today));
    //   for (let i = 0; i < 12; i++) {
    //     var new_date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    //     dates.push({
    //       date: this.toYYYYmmDD(new_date),
    //       label: this.MonthMap[new_date.getMonth() + 1] + ' ' + new_date.getFullYear(),
    //     });
    //   }
    //   return dates;
    // },
    // urlParam() {
    //   return this.$route.params.date || '';
    // },
  },
  mounted() {
    this.FetchData();
  },
  watch: {
    start_date() {
      this.FetchData();
    },
    end_date() {
      this.FetchData();
    },
  },
};
</script>

<style scoped>
.dates-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  padding: 10px;
}
.table-footer td {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}
</style>
