<template>
  <v-row class="ma-0 pa-6">
    <div class="flex-grow-1 d-flex justify-space-between mb-6">
      <h1>Jobs Approved Today</h1>
    </div>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :items-per-page="-1"
      class="elevation-1 r-approved-today-table"
      style="width: 100%"
      :loading="is_loading"
      show-expand
      single-expand
      @click:row="ParentRowClick"
      :item-class="(item) => getDynamicClass(item)"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn :to="'/job/' + item.id" color="primary">View</v-btn>
      </template>

      <template v-slot:item.booked_date="{ item }">
        {{ item.appDate | YMD_to_DMY }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="py-8 px-4 d-flex flex-column" style="gap: 30px">
            <div v-if="item.parts.length > 0">
              <h3 class="mb-3">Misc. Costs</h3>
              <v-data-table
                :items="item.parts"
                class="elevation-1"
                :headers="Partheaders"
                hide-default-footer
              >
              </v-data-table>
            </div>
            <div v-if="item.jobcomponents.length > 0">
              <h3 class="mb-3">Job Parts</h3>
              <v-data-table
                :items="item.jobcomponents"
                class="elevation-1"
                :headers="Component_headers"
                hide-default-footer
              >
              </v-data-table>
            </div>
          </div>
        </td>
      </template>

      <!-- <template v-slot:body.append>
        <tr>
          <td colspan="5">Total: {{ CalculateJobsAssignedTotal }}</td>
        </tr>
      </template> -->
    </v-data-table></v-row
  >
</template>

<script>
export default {
  name: 'Awaiting_Parts',
  data() {
    return {
      is_loading: false,
      IsFetchingPDF: false,
      headers: [
        {
          text: 'Job',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Customer', value: 'company.name' },
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'actions' },
        { text: '', value: 'data-table-expand' },
      ],

      Partheaders: [
        {
          text: 'Supplier',
          value: 'company.name',
          sortable: false,
          width: '25%',
        },
        { text: 'Part', value: 'part_code', sortable: false, width: '25%' },
        { text: 'Description', value: 'part_description', sortable: false },
        { text: 'Pack Qty', value: 'pack_qty', sortable: false },
        { text: 'Packs Ordered', value: 'packs_ordered', sortable: false },
      ],
      Component_headers: [
        {
          text: 'Supplier',
          value: 'component.company.name',
          sortable: false,
          width: '25%',
        },
        { text: 'Part', value: 'component.part_code', sortable: false, width: '25%' },
        { text: 'Description', value: 'component.description', sortable: false },
        { text: 'Qty Needed', value: 'quantity', sortable: false },
        { text: 'Qty In Stock', value: 'component.qty_in_stock', sortable: false },
      ],
      jobs: [],
    };
  },

  methods: {
    ParentRowClick(item, slot) {
      // console.log(item);
      if (item.jobcomponents.length > 0 || item.parts.length > 0)
        return slot.expand(!slot.isExpanded);
    },
    getDynamicClass(item) {
      console.log(item);
      let dynamic_class = '';

      if (!item) return dynamic_class;

      if (item.jobcomponents.length > 0 || item.parts.length > 0) dynamic_class = 'r-has-expansion';
      return dynamic_class;
    },
  },
  computed: {},
  mounted() {
    this.is_loading = true;
    this.$store
      .dispatch('get_report_data_for_tables', 'jobs_approved_today')
      .then((res) => {
        this.jobs = res;
      })
      .finally(() => {
        this.is_loading = false;
      });
  },
};
</script>

<style scoped>
>>> .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: unset !important;
  background: #f1f1f1;
}

>>> .r-approved-today-table tr.r-has-expansion {
  cursor: pointer;
}

>>> .r-approved-today-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.r-has-expansion):not(.v-data-table__expanded__content) {
  cursor: not-allowed;
}

>>> .r-approved-today-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(.r-has-expansion):not(.v-data-table__expanded__content)
  td:last-child
  > button {
  display: none !important;
}
</style>
