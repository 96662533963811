var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-6"},[_c('div',{staticClass:"flex-grow-1 d-flex justify-space-between mb-6"},[_c('h1',[_vm._v("Jobs Approved Today")])]),_c('v-data-table',{staticClass:"elevation-1 r-approved-today-table",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.jobs,"items-per-page":-1,"loading":_vm.is_loading,"show-expand":"","single-expand":"","item-class":function (item) { return _vm.getDynamicClass(item); }},on:{"click:row":_vm.ParentRowClick},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":'/job/' + item.id,"color":"primary"}},[_vm._v("View")])]}},{key:"item.booked_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("YMD_to_DMY")(item.appDate))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"py-8 px-4 d-flex flex-column",staticStyle:{"gap":"30px"}},[(item.parts.length > 0)?_c('div',[_c('h3',{staticClass:"mb-3"},[_vm._v("Misc. Costs")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":item.parts,"headers":_vm.Partheaders,"hide-default-footer":""}})],1):_vm._e(),(item.jobcomponents.length > 0)?_c('div',[_c('h3',{staticClass:"mb-3"},[_vm._v("Job Parts")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":item.jobcomponents,"headers":_vm.Component_headers,"hide-default-footer":""}})],1):_vm._e()])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }