<template>
  <v-col class="dash-col" v-if="this.CurrentUser['col_' + this.col_Id]">
    <p class="riy primary darken-3 white--text" @click="test()">
      {{ ColumnName(this.col_Id) }} - {{ this.jobs ? this.jobs.length : 'N/A' }}
    </p>

    <!-- <transition-group name="snackbar" tag="div"> -->
    <div
      v-for="(job, index) in filtered_assigned_user"
      :key="job.id"
      :data-index="index"
      :ref="job.id"
      :data-status_id="job.status_id"
      :id="job.id"
      class="job-card my-4"
      @click="goToJob(job.id)"
    >
      <div
        class="job-card-top-row d-flex justify-space-between"
        v-bind:class="{
          'normal-priority': job.priority_id <= 2,
          'high-priority': job.priority_id == 3,
          'emergency-priority': job.priority_id == 4,
        }"
      >
        {{ job.id }}
        <span
          v-if="
            job &&
              job.company &&
              ((job.company.term_id && job.company.term_id == 1) ||
                (job.company.company_status_id && job.company.company_status_id == 5))
          "
          class="is_cash_only_badge py-1 px-2 d-flex"
        >
          PROFORMA
        </span>
        <div
          class="header-roles-container d-flex"
          v-if="job.bBer || job.bRnr || job.company.company_status_id == 2"
        >
          <div
            class="header-role-chip mx-1 px-4 py-1"
            style="background: rgba(0, 0, 0, 0.5)"
            v-if="job.bBer"
          >
            BER
          </div>
          <div class="header-role-chip mx-1 px-4 py-1 " v-if="job.bRnr">RNR</div>
          <div
            style="background: rgba(0, 0, 0, 0.75)"
            class="header-role-chip mx-1 px-4 py-1 css-animate-blink"
            v-if="job.company.company_status_id == 2"
          >
            On stop
          </div>
        </div>
      </div>
      <div class="job-card-content-row d-flex flex-column">
        <p class="mb-2 body-2">
          <span style="line-height: 1.75" class="mr-2">{{
            job.company && job.company.name ? job.company.name : 'N/A'
          }}</span>

          <span
            v-if="
              job &&
                job.company &&
                job.company.company_status_id &&
                job.company.company_status_id == 3
            "
            class="is_cash_only_badge py-1 px-2 d-flex"
            style="background: #e77b20;"
          >
            PO Required
          </span>
          <span
            v-if="
              job &&
                job.company &&
                job.company.company_status_id &&
                job.company.company_status_id == 4
            "
            class="is_cash_only_badge py-1 px-2 d-flex"
            style="background: #e77b20;"
          >
            Authorisation Required
          </span>
        </p>
        <hr v-if="job.manufacturer || job.machine_model" />
        <p class="mb-2 mt-1 body-2" v-if="job.manufacturer">
          {{ job.manufacturer }}
        </p>
        <p class="mb-2 body-2" v-if="job.machine_model">
          {{ job.machine_model }}
        </p>
        <hr v-if="job.description" />
        <p class="mb-2 mt-1 body-2" v-if="job.description">
          {{ job.description }}
        </p>
        <hr v-if="job.order_number && !job.is_warranty" />
        <p
          class="mb-1 font-weight-medium caption mt-1 primary--text body-2"
          v-if="job.order_number && !job.is_warranty"
        >
          {{ job.order_number }}
        </p>
        <hr v-if="job.is_warranty" />
        <div v-if="job.is_warranty" class="is_warranty_badge py-1 px-2 d-flex mt-2 mr-auto">
          Under Warranty
        </div>

        <div
          v-if="job.appDate && (job.status_id == 5 || job.status_id == 6)"
          style="font-size: 12px"
          class="appDate_card_badge py-1 px-2 d-flex mt-1 mr-auto"
          title="Approved date"
        >
          <v-icon small color="white" class="mr-2">mdi-calendar-month</v-icon>
          {{ job.appDate | YMD_to_DMY }}
        </div>
      </div>
    </div>
    <!-- </transition-group> -->

    <!-- <v-btn @click="showmore" v-if="filtered_job_list.length > 20">{{
      this.show_more_toggled ? "Show Less" : "Show More"
    }}</v-btn> -->
  </v-col>
</template>

<script>
// import JobCard from "./JobCard.vue";

export default {
  name: 'JobDashboardColumn',
  components: {
    // JobCard,
  },
  props: {
    jobs: Array,
    filter: String,
    maxIndex: Number,
    col_Id: String,
    assigned_user: Number,
  },
  data() {
    return {
      local_max_index: 0,
      show_more_toggled: false,
    };
  },
  computed: {
    CurrentUser() {
      return this.$store.getters.getCurrentUserObj;
    },
    filtered_job_list() {
      if (!this.filter.length > 0) return this.jobs;
      return this.jobs.filter((job) => {
        return (
          job['id'].toString().includes(this.filter) ||
          (job['company'] &&
            job['company']['name']
              .toString()
              .toLowerCase()
              .includes(this.filter.toLowerCase()))
        );
        // str.includes("world");
      });
    },

    filtered_assigned_user() {
      if (!this.assigned_user) return this.filtered_job_list;
      return this.filtered_job_list.filter((job) => {
        return job['user_id'] && job['user_id'].toString() == this.assigned_user.toString();
        // str.includes("world");
      });
    },

    max_index() {
      return Math.max(this.maxIndex, this.local_max_index);
    },
  },
  methods: {
    test() {
      console.log(this.$refs['50747']);
    },
    get_status_text(status_id) {
      if (!status_id) return 0;
      return this.$store.getters.get_status(status_id);
    },
    get_priority_text(priority_id) {
      if (!priority_id) return '';
      return this.$store.getters.get_priority(priority_id);
    },
    ColumnName(id) {
      switch (id) {
        case '1':
          return 'Collection';

        case '2':
          return 'Assessment';

        case '3':
          return 'Customer Quotation';

        case '4':
          return 'Awaiting Acceptance';

        case '5':
          return 'Go Ahead';
        case '6':
          return 'In Progress';
        case '7':
          return 'For Delivery';
        case '8':
          return 'For Invoicing';

        default:
          break;
      }
    },

    showmore() {
      if (this.local_max_index != 1000) this.local_max_index = 1000;
      else this.local_max_index = 0;
      this.show_more_toggled = !this.show_more_toggled;
    },
    goToJob(job_id) {
      if (job_id) {
        // this.$store.commit("SetSnackbarData", {
        //   message: "Not Implemented Yet - Go To Job",
        //   type: "warning",
        // });

        console.log(job_id);
        this.$router.push('/job/' + job_id);
      }
    },
  },
};
</script>

<style scoped>
.job-card {
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px -8px;
  border: 1px solid #f3f3f3;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  background: white;
}

.job-card hr {
  border-top: 1px solid #eaeaea;
}
.job-card > div {
  padding: 10px 15px;
}

.job-card-top-row {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* .job-card-content-row {
} */
/* .job-card,
.job-card * {
} */
.job-card:hover {
  background: #f3f3f3;
}
.riy {
  background: #f3f3f3;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px -6px;
}

.dash-col {
  min-width: 300px;
}

@media only screen and (max-width: 400px) {
  .dash-col {
    min-width: 100%;
    padding: 0px;
  }
}
.is_warranty_badge {
  background: #033c73;
  color: white;
  font-size: 12px;
  border-radius: 3px;
}

.appDate_card_badge {
  background: #e77b20;
  color: white;
  font-size: 12px;
  border-radius: 3px;
}

.is_cash_only_badge {
  background: #800382;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  display: inline-flex !important;
  line-height: 1;
}

.css-animate-blink {
  animation: blink 2.25s linear infinite;
  animation-fill-mode: both;
  /* animation-timing-function: ease-in-out; */
}

@keyframes blink {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
</style>
