<template>
  <v-navigation-drawer fixed temporary v-model="localDrawer" width="320">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ UserData.name ? UserData.name : 'Not logged in' }}
        </v-list-item-title>
        <!-- <v-list-item-subtitle>
          <div
            class="d-flex"
            style="width: 100%; flex-wrap: wrap"
            v-if="UserData && UserData.roles"
          >
            <div
              class="header-role-chip mx-1 px-4 py-1"
              v-for="role in UserData.roles"
              :key="role"
            >
              {{ role }}
            </div>
          </div>
        </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
      <!-- <v-list-item link @click="gotoDashboard">
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item> -->

      <v-list-item to="/dashboard" link>
        <v-list-item-icon>
          <v-icon color="primary">mdi-view-dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-divider></v-divider> -->
      <!--
      <v-list-item link @click="gotoBookCollection">
        <v-list-item-title>Book a Collection</v-list-item-title>
      </v-list-item> -->

      <v-list-item to="/jobs/book" link v-if="this.checkPermission('can_book_collection')">
        <v-list-item-icon>
          <v-icon color="success darken-2">mdi-book-open</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Book a Collection</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/jobs/create" link v-if="this.checkPermission('can_create_job')">
        <v-list-item-icon>
          <v-icon color="success darken-2">mdi-plus-circle</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Create a Job</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item link @click="gotoCreateJob">
        <v-list-item-title>Create a Job</v-list-item-title>
      </v-list-item> -->
      <v-divider></v-divider>

      <v-list-item to="/search/" link v-if="this.checkPermission('can_access_search')">
        <v-list-item-icon>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Search</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--
      <v-list-item link @click="gotoSearch">
        <v-list-item-title>Search</v-list-item-title>
      </v-list-item> -->

      <v-list-item to="/deliveries/" link>
        <v-list-item-icon>
          <v-icon>mdi-truck</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Deliveries</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-expansion-panels>
        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header> Management </v-expansion-panel-header>
          <v-expansion-panel-content class="padding-0-child">
            <v-list-item
              to="/management/stats/"
              link
              v-if="this.checkPermission('can_view_stats_dashboard')"
            >
              <v-list-item-icon>
                <v-icon>mdi-trending-up</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Stats Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="link.url"
              link
              v-for="link in ManagementLinksWithoutRole"
              :key="link.name"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <template v-if="this.checkPermission('can_access_full_management_menu')">
              <v-list-item
                :to="link.url"
                link
                v-for="link in ManagementLinksWithRole"
                :key="link.name"
              >
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ link.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item></template
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>

      <!-- StockSection -->

      <v-expansion-panels>
        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header> Stock </v-expansion-panel-header>
          <v-expansion-panel-content class="padding-0-child">
            <v-list-item :to="link.url" link v-for="link in StockSection" :key="link.name">
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>

      <!-- end stock section -->
      <v-expansion-panels v-if="this.checkPermission('can_access_reports')">
        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header> Reports </v-expansion-panel-header>
          <v-expansion-panel-content class="padding-0-child">
            <v-list-item
              :to="link.url"
              link
              v-for="link in ReportsLinks"
              :key="link.name"
              :disabled="link.disabled"
            >
              <v-list-item-icon>
                <v-icon color="warning darken-1">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>

      <v-expansion-panels v-if="this.checkPermission('can_access_sage_menu')">
        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header>Finance</v-expansion-panel-header>
          <v-expansion-panel-content class="padding-0-child">
            <v-list-item
              :to="link.url"
              link
              v-for="link in SageLinks"
              :key="link.name"
              :disabled="link.disabled"
            >
              <v-list-item-icon>
                <v-icon color="warning darken-1">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>

      <v-list-item link to="/settings/">
        <v-list-item-icon>
          <v-icon color="warning darken-1">mdi-lock-open-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon color="warning darken-1">mdi-lock-open-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- <v-btn @click="logout" text>
      <v-icon>mdi-lock-open-outline</v-icon>
      <span class="ml-2">Logout</span>
    </v-btn> -->

    <!-- <template v-slot:append>
      <div class="pa-2 r-logout-drawer-wrapper mb-2">
        <v-btn block @click="logout" color="primary">
          <v-icon>mdi-lock-open-outline</v-icon>
          <span class="ml-2">Logout</span>
        </v-btn>
      </div>
    </template> -->
  </v-navigation-drawer>

  <!-- <p>Reports</p>
    <p>Accounts</p>
    <p>Management</p> -->
</template>

<script>
export default {
  name: 'Menu',
  props: { value: { type: Boolean } },
  data() {
    return {
      localDrawer: this.value,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Account', icon: 'mdi-account-box' },
        { title: 'Admin', icon: 'mdi-gavel' },
      ],
      SageLinks: [
        {
          url: '/finance/export_report/',
          icon: 'mdi-account-key',
          text: 'Export Report',
        },
        {
          url: '/finance/export/',
          icon: 'mdi-account-key',
          text: 'Export',
        },
      ],
      ManagementLinksWithoutRole: [
        {
          url: '/management/contacts/',
          icon: 'mdi-account-box',
          text: 'Contacts',
        },
        {
          url: '/management/suppliers/',
          icon: 'mdi-domain',
          text: 'Suppliers',
        },
        {
          url: '/management/customers/',
          icon: 'mdi-store',
          text: 'Customers',
        },
      ],
      ManagementLinksWithRole: [
        {
          url: '/management/users/',
          icon: 'mdi-account-multiple',
          text: 'Users',
        },
        {
          url: '/management/graphs/engineer-graphs/',
          icon: 'mdi-account-multiple',
          text: 'Engineer Graphs',
        },
        {
          url: '/management/roles/',
          icon: 'mdi-account-key',
          text: 'Roles',
        },

        {
          url: '/management/departments/',
          icon: 'mdi-account-key',
          text: 'Departments',
        },
        {
          url: '/management/labour-rates/',
          icon: 'mdi-abacus',
          text: 'Labour Rates',
        },
        {
          url: '/management/common-phrases/',
          icon: 'mdi-abacus',
          text: 'Common Phrases',
        },
      ],
      ReportsLinks: [
        {
          url: '/reports/jobsByEngineer/',
          icon: 'mdi-note',
          text: 'Jobs In Progress',
        },
        {
          url: '/reports/awaiting_po/',
          icon: 'mdi-note',
          text: 'Overdue Order Numbers',
        },
        {
          url: '/reports/awaiting_acceptance/',
          icon: 'mdi-note',
          text: 'Jobs Awaiting Acceptance',
        },
        {
          url: '/reports/awaiting_collection/',
          icon: 'mdi-note',
          text: 'Jobs Awaiting Collection',
        },
        {
          url: '/reports/awaiting_delivery/',
          icon: 'mdi-note',
          text: 'Jobs Awaiting Delivery',
        },
        {
          url: '/reports/go_ahead/',
          icon: 'mdi-note',
          text: 'Jobs Overdue',
        },
        {
          url: '/reports/monthly_turnaround/',
          icon: 'mdi-note',
          text: 'Monthly Output',
        },
        {
          url: '/reports/monthly_sales/',
          icon: 'mdi-note',
          text: 'Monthly Sales',
        },

        {
          url: '/reports/booked_in_by_month/',
          icon: 'mdi-note',
          text: 'Monthly Booked In',
        },

        {
          url: '/reports/jobs_quoted_by_date/',
          icon: 'mdi-note',
          text: 'Jobs Quoted By Date',
        },

        {
          url: '/reports/awaiting_parts/',
          icon: 'mdi-note',
          text: 'Jobs Awaiting Parts',
        },
        {
          url: '/reports/jobs_approved_today/',
          icon: 'mdi-note',
          text: 'Jobs Approved Today',
        },
        {
          url: '/reports/sales_by_month/',
          icon: 'mdi-note',
          // text: "Invoiced Jobs - Last Month",
          text: 'Invoiced Jobs by Month',
          // disabled: true,
        },

        {
          url: '/reports/components_stock_report/',
          icon: 'mdi-note',
          // text: "Invoiced Jobs - Last Month",
          text: 'Job Components - Stock Report',
          // disabled: true,
        },
      ],
      StockSection: [
        {
          url: '/components_list/',
          icon: 'mdi-meter-electric',
          text: 'Components',
        },
        {
          url: '/stock/components_to_order/',
          icon: 'mdi-note',
          text: 'Components to Order',
        },
      ],
    };
  },
  methods: {
    CloseDrawer() {
      this.$emit('CloseDrawer');
    },
    gotoDashboard() {
      this.$router.push('/dashboard');
    },
    gotoBookCollection() {
      this.$router.push('/jobs/book');
    },
    gotoCreateJob() {
      this.$router.push('/jobs/create');
    },
    gotoSearch() {
      this.$router.push('/search/');
    },
    logout() {
      this.$emit('logout');
    },
  },
  watch: {
    value: function() {
      this.localDrawer = this.value;
    },
    localDrawer: function() {
      this.$emit('input', this.localDrawer);
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    UserData() {
      return this.$store.getters.getCurrentUserObj;
    },
  },
};
</script>

<style scoped>
/* .r-logout-drawer-wrapper {
} */

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel {
  transition: 0.2s ease-in-out;
}

.v-expansion-panel.v-expansion-panel--active {
  background-color: #f0f0f0 !important;
}

.padding-0-child >>> .v-expansion-panel-content__wrap {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
